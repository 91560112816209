<template>
<div class="diagnostic_box" id="diagnostic_box">
  <div style="background: #fff;padding: 30px;">
    <div class="diagnostic_header">
      <h5>{{companyData.name}}诊断报告（{{getStatus(companyData.statu)}}）</h5>
      <p class="look_out">注：本次评测时间为{{ time[0] }}，最终报告生成时间为{{ time[1] }}。</p>
      <div class="header_mian">
        <div class="h_left">
          <div class="hl_header">
            <div class="hl_score" id="score"></div>
            <div class="hl_txt">
              <h4>数字化水平评测</h4>
              <p>依据工业和信息化部发布《中小企业数字化水平评测指标（2022年版）》<span class="corporation">{{ evaluation.name }}</span> ，数字化水平达到<span class="red" v-if="evaluation.lv">{{evaluation.lv}}</span><span class="red" v-else>无等级</span></p>
            </div>
          </div>
          <div class="hl_performance">
            <h4>整体表现：</h4>
            <p>当前企业在<span class="red">{{ expression.weakness[0] }}、{{ expression.weakness[1] }}</span>方面较为薄弱，建议加强该板块的内容建设及数字化投入；在<span class="blue">{{ expression.protrude[0] }}、{{ expression.protrude[1] }}</span>方面较为突出，建议继续保持，加快实现全流程数字化转型。</p>
          </div>
        </div>
        <div class="h_right" id="chart1"></div>
      </div>
    </div>
    <!--  数转能力分析-->
    <div class="analysis_box">
      <div class="analysis_header">
        <h3>数转能力分析</h3>
        <div class="line"></div>
      </div>
      <div class="item_box">
        <div class="ab_item">
          <h4>数字化基础</h4>
          <div class="revolution_box">
            <div class="chart_box" id="chart2"></div>
            <div class="revolution_table">
              <el-scrollbar class="quest-scroll">
                <ul>
                  <li v-for="(item,index) in foundation" :key="item.target_id2">
                    <div class="r_name">
                      <p>{{ item.name }}</p>
                      <p class="red">{{ item.value }}分</p>
                    </div>
                    <div class="r_txt">
                      <p>{{ getRandomComment(item.comment) }}</p>
                    </div>
                  </li>
                </ul>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="ab_item">
          <h4>数字化经营</h4>
          <div class="revolution_box">
            <div class="chart_box" id="chart3"></div>
            <div class="revolution_table">
              <el-scrollbar class="quest-scroll">
                <ul>
                  <li v-for="(item,index) in business" :key="item.target_id2">
                    <div class="r_name">
                      <p>{{ item.name }}</p>
                      <p class="red">{{ item.value }}分</p>
                    </div>
                    <div class="r_txt">
                      <p>{{ getRandomComment(item.comment) }}</p>
                    </div>
                  </li>
                </ul>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="ab_item">
          <h4>数字化管理</h4>
          <div class="revolution_box">
            <div class="chart_box" id="chart4"></div>
            <div class="revolution_table">
              <el-scrollbar class="quest-scroll">
                <ul>
                  <li v-for="(item,index) in manage" :key="item.target_id2">
                    <div class="r_name">
                      <p>{{ item.name }}</p>
                      <p class="red">{{ item.value }}分</p>
                    </div>
                    <div class="r_txt">
                      <p>{{ getRandomComment(item.comment) }}</p>
                    </div>
                  </li>
                </ul>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="ab_item">
          <h4>数字化成效</h4>
          <div class="revolution_box">
            <div class="chart_box" id="chart5"></div>
            <div class="revolution_table">
              <el-scrollbar class="quest-scroll">
                <ul>
                  <li v-for="(item,index) in effect" :key="item.target_id2">
                    <div class="r_name">
                      <p>{{ item.name }}</p>
                      <p class="red">{{ item.value }}分</p>
                    </div>
                    <div class="r_txt">
                      <p>{{ getRandomComment(item.comment) }}</p>
                    </div>
                  </li>
                </ul>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  解决方案:富文本-->
    <div class="solution_box" v-if="solution !== ''">
      <div class="solution_header">
        <h3>解决方案</h3>
        <div class="line"></div>
      </div>
      <div class="solution_main" v-html="solution"></div>
    </div>

    <!--  产品推荐-->
    <div class="recommended_box" v-if="recommended.length>0">
      <div class="recommended_header">
        <h3>产品推荐</h3>
        <div class="line"></div>
      </div>
      <div class="recommended_main">
        <ul>
          <li v-for="(item,index) in recommended" :key="item.product.id">
            <div class="r_logo">
              <img :src="item.product.product_img[0]" alt="">
            </div>
            <div class="r_content">
              <h4>{{ item.product.name }}</h4>
              <div class="tag_box">
                <div v-for="(item1,index) in item.product.tags" :key="index">{{ item1 }}</div>
              </div>
              <p>{{ item.product.feature }}</p>
              <div class="r_star">
                <span class="star_name">推荐指数</span>
                <el-rate
                    v-model="item.star"
                    disabled
                    text-color="#ff9900"
                    score-template="{item.star}">
                </el-rate>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
<!--  <div style="background: #fff;padding: 30px" class="btn_box">
    <a class="blue" href="#">下载PDF报告</a>
    <router-link to="/diagnosticReport">返回</router-link>
  </div>-->
</div>
</template>

<script>
import {companyInfo, Full} from "../../request/oneEnterOneFileApi";

export default {
  name: "index",
  data(){
    return {
      companyData:{
        name:'龙智造',
        statu:1
      },
      company_id: null,
      time:['2024年3月21日','2024年3月24日'], //测评时间
      score:10,
      dictionary_type:'',
      business:[],
      effect:[],
      foundation:[],
      manage:[],
      solution:'',//解决方案
      evaluation:{ //测评信息
        name:'重庆龙易购科技有限公司',
        lv:'二级'
      },
      expression:{ //整体表现：薄弱与突出
        weakness:'数字化基础、数字化经营',
        protrude:'数字化管理、数字化成效'
      },
      recommended:[],
      chart1 : [
        { text: "数字化基础", max: 20 },
        { text: "数字化管理", max: 25 },
        { text: "数字化成效", max: 10 },
        { text: "数字化经营", max: 45}
      ],
      chart2 : [
        { text: "设备系统", max: 10,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "网络安全", max: 7.5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "数据资源", max: 7.5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' }
      ],
      chart3 : [
        { text: "研发设计", max: 6.5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "业务协同", max: 4.5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "产品服务", max: 7.3,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "营销管理", max: 4.5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "采购供应", max: 4.5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "生产管控", max: 18.9,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
      ],
      chart4 : [
        { text: "经营战略", max: 3,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "资金投入", max: 5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "人才建设", max: 5,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "管理机制", max: 7,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' }
      ],
      chart5 : [
        { text: "产品质量", max: 3,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "价值效益", max: 3,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
        { text: "生产效率", max: 4,txt:'建议企业逐步推动数字化覆盖范围延伸，逐步提高数字化设备使用率' },
      ]
    }
  },
  mounted() {
    let id = this.company_id = this.$route.query.id;
    let isDownload = this.$route.query.download;
    this.getData(id, () => {
      //解决方案与产品推荐
      this.$api.Full({company_id:id}).then(res=>{
        console.log(res,158)
        this.solution = res.data.solution
        this.recommended = res.data.recommend.map(item => ({
          ...item,
          star: Number(item.star) // 使用Number函数将star属性的值转换为数字
        }));
        if (this.$route.query.hasOwnProperty('content')){
          this.recommended = JSON.parse(this.$route.query.selectedProduct).map(item => {
            // 从每个对象中提取 start 属性
            const { star, ...product } = item;

            // 组装新的对象结构
            return { product, star };
          });
          this.solution = this.$route.query.content
        }
        console.log(this.recommended,11)
        isDownload && this.uploadPDF();
      })
    })
},
  methods:{
    //下载PDF
    uploadPDF() {
      const fileName = `${this.companyData.name}诊断报告（${this.getStatus(this.companyData.statu)}）`;
      this.getPdf('#diagnostic_box', fileName, () => {
        // 关闭当前窗口
        window.close();
      });
    },
    getRandomComment(commentArray) {
      // 随机生成一个索引
      const randomIndex = Math.floor(Math.random() * commentArray.length);
      // 返回随机选择的评论
      return commentArray[randomIndex];
    },
    getData(id, callback){
      this.$api.companyInfo({company_id: id}).then(res=>{
        console.log(res,999)
        this.time[0] = res.data.diagnose_start_time;
        this.time[1] = res.data.diagnose_end_time;
        this.companyData.name = res.data.company
        this.companyData.statu = res.data.diagnose_status
        this.evaluation.name = res.data.company
        this.evaluation.lv = res.data.diagnose_level
        this.score = res.data.diagnose_score
        this.dictionary_type = res.data.dictionary_type
        this.instrument(parseFloat(this.score));
        this.$api.companyPortrayal({company_id:id,dictionary_type:this.dictionary_type}).then(res=>{

          /*整体表现的值*/
          let abc = res.data.entirety
          abc.sort((a, b) => a.value - b.value);
          let minValueObjects = abc.slice(0, 2);
          let maxValueObjects = abc.slice(-2);
          this.expression.weakness = minValueObjects.map(obj => obj.name);
          this.expression.protrude = maxValueObjects.map(obj => obj.name);

          this.business = res.data.business
          this.effect = res.data.effect
          this.foundation = res.data.foundation
          this.manage = res.data.manage
          // this.$forceUpdate();
          let nameList = [];
          let valueList = [];
          for (let i = 0; i < res.data.entirety.length; i++) {
            let nameData = {name: res.data.entirety[i].name, max: res.data.entirety[i].max || Number(res.data.entirety[i].value)+10,value:res.data.entirety[i].value};
            nameList.push(nameData);
            valueList.push(res.data.entirety[i].value);
          }

          let nameList1 = [];
          let valueList1 = [];
          for (let i = 0; i < res.data.foundation.length; i++) {
            let nameData = {name: res.data.foundation[i].name, max: res.data.foundation[i].max || Number(res.data.foundation[i].value)+10,value:res.data.foundation[i].value};
            nameList1.push(nameData);
            valueList1.push(res.data.foundation[i].value);
          }

          let nameList2 = [];
          let valueList2 = [];
          for (let i = 0; i < res.data.business.length; i++) {
            let nameData = {name: res.data.business[i].name, max: res.data.business[i].max || Number(res.data.business[i].value)+10,value:res.data.business[i].value};
            nameList2.push(nameData);
            valueList2.push(res.data.business[i].value);
          }

          let nameList3 = [];
          let valueList3 = [];
          for (let i = 0; i < res.data.manage.length; i++) {
            let nameData = {name: res.data.manage[i].name, max: res.data.manage[i].max || Number(res.data.manage[i].value)+10,value:res.data.manage[i].value};
            nameList3.push(nameData);
            valueList3.push(res.data.manage[i].value);
          }

          let nameList4 = [];
          let valueList4 = [];
          for (let i = 0; i < res.data.effect.length; i++) {
            let nameData = {name: res.data.effect[i].name, max: res.data.effect[i].max || Number(res.data.effect[i].value)+10,value:res.data.effect[i].value};
            nameList4.push(nameData);
            valueList4.push(res.data.effect[i].value);
          }

          const _color = ['rgb(227,35,37)']
          const _color1 = ['rgb(91,124,253)']
          const _color2 = ['rgb(26,207,155)']
          const _color3 = ['rgb(134,97,254)']
          this.leida(nameList,valueList,_color,'chart1','总览')
          this.leida(nameList1,valueList1,_color,'chart2','数字化基础')
          this.leida(nameList2,valueList2,_color1,'chart3','数字化经营')
          this.leida(nameList3,valueList3,_color2,'chart4','数字化管理')
          this.leida(nameList4,valueList4,_color3,'chart5','数字化成效')

          callback && callback()
        })
      })
    },
    gerLeiDa(){

    },
    getStatus(e){
      if (e==2){
        return '完整版'
      }else {
        return '基础版'
      }
    },
  leida(nameList,valueList,color,id,name) {
    const myChart = this.$echarts.init(
        document.getElementById(id)
    )
    myChart.setOption({
      tooltip: {
        trigger: "item",
        backgroundColor: "rgb(255,255,255)",
        borderColor: 'rgb(255,255,255)',
        extraCssText: 'box-shadow: 0 0 15px rgba(0,0,0,0.15);border-radius: 4px;',
        textStyle: {
          color: "#23282d", // 设置字体颜色
          fontSize: 12, // 设置字体大小
        },
        formatter: function(params) {
          var result = '<p>'+ params.name +'</p>';
          params.value.forEach(function(val, index) {
            var circleColor = color[0];
            // 自定义 tooltip 的内容，包括小圆球的颜色和数据项的名称及其值
            result += '<p style="margin-top: 7px">' + '<span style="display:inline-block;margin-right:5px;border-radius:8px;width:8px;height:8px;background-color:' + circleColor + ';"></span>' + '<span style="color: #3c4248;margin-right: 10px">' + nameList[index].name + '</span>' + '<span style="color: '+circleColor+';font-family: DINR;font-weight: 500">' + val + '</span>' + '</p>';
          });
          return result;
        }
      },
      radar: [
        {
          indicator: nameList,
          center: ["50%", "50%"],
          radius: '70%',
          name: {
            textStyle: {
              color: '#23282d',
              fontSize: 12,
            },
            formatter: function(value, indicator) {
              let weight = (indicator.max - 5).toFixed(1);
              return '{a|' + value + '}' +'\n' +'{b|权重' + weight +'}';
            },
            rich: {
              a: {
                color: '#23282d',
              },
              b: {
                color: '#909DA5',
                lineHeight: 20, // 调整数值距离名称的距离
              },
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgb(228,231,233)",
              opacity: 1,
              width: 1,
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: "rgb(228,231,233)",
              opacity: 0,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(228,231,233,1)",
              type: "solid",
            },
          },
        },
      ],
      series: [
        {
          type: "radar",
          symbol: 'circle',
          symbolSize: 8,
          tooltip: {
            trigger: "item",
          },
          data: [
            {
              value: valueList,
              name: name,
            },
          ],
          itemStyle: {
            normal: {
              borderColor: color[0],
              borderWidth: 0.8,
              color:'rgb(255,255,255)'
            },
          },
          lineStyle: {
            normal: {
              color: color[0],
              width: 2,
            },
          },
          areaStyle: {
            normal: {
              color: color[0],
              opacity: 0.2,
            },
          },
        },
      ],
    })
},
  instrument(value){
    const score = value.toFixed(1)
      const myChart = this.$echarts.init(
          document.getElementById('score')
      )
      const startAngle = 450
      const endAngle = -60
      myChart.setOption(
          {
            title: [
              {
                text: `{value|${score}}{name|${'分'}}`,
                left: 'center',
                bottom: '35%',
                textStyle: {
                  rich: {
                    value: {
                      fontSize: '30',
                      color: '#3984F5',
                      padding: [4, 0]
                    },
                    name: {
                      fontSize: '30',
                      color: '#3984F5'
                    }
                  }
                }
              }
            ],
            series: [
              {
                name: '内层带指针',
                type: 'gauge',
                radius: '100%',
                startAngle,
                endAngle,
                axisLine: {
                  lineStyle: {
                    color: [[1, '#EBF4FF']],
                    width: 5
                  }
                },
                axisTick: {
                  show: false
                },
                splitLine: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                detail: {
                  show: false
                },
                pointer: {
                  show: true,
                  length: '22%',
                  offsetCenter: [0, '-78%'],
                  width: 5,
                  icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                  itemStyle: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#4576ff'
                        },
                        {
                          offset: 0.2,
                          color: '#4576ff'
                        },
                        {
                          offset: 0.8,
                          color: '#4576ff'
                        },
                        {
                          offset: 1,
                          color: '#4576ff'
                        }
                      ]
                    }
                  }
                },
                progress: {
                  show: true,
                  width: 5,
                  itemStyle: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#4576ff'
                        },
                        {
                          offset: 1,
                          color: '#3961d2'
                        }
                      ]
                    }
                  }
                },
                data: [
                  {
                    value: score
                  }
                ]
              },
              {
                name: '中心效果圆',
                type: 'gauge',
                radius: '78%',
                startAngle: 0,
                endAngle: 360,
                axisLine: {
                  lineStyle: {
                    color: [[1, '#EBF4FF']],
                    width: 2,
                    shadowBlur: 40, // 设置阴影模糊程度
                    shadowColor: 'rgb(30,139,222)' // 设置阴影颜色
                  }
                },
                axisTick: {
                  show: false
                },
                splitLine: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                detail: {
                  show: false
                },
                pointer: {
                  show: false
                },
                progress: {
                  show: false,
                  width: 8,
                  itemStyle: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgb(0, 224, 205, 0)'
                        },
                        {
                          offset: 0.1,
                          color: 'rgb(0, 224, 205, 0)'
                        },
                        {
                          offset: 0.3,
                          color: 'rgb(0, 224, 205, 0)'
                        },
                        {
                          offset: 0.4,
                          color: 'rgb(0, 224, 205, 0.05)'
                        },
                        {
                          offset: 0.5,
                          color: 'rgb(0, 224, 205, 0.1)'
                        },
                        {
                          offset: 1,
                          color: 'rgb(0, 224, 205, 0.3)'
                        }
                      ],
                    }
                  }
                },
                data: [
                  {
                    value: 100
                  }
                ]
              }
            ]
          }
      )

    }
  },
}
</script>

<style scoped lang="scss">
.diagnostic_box{
  padding: 18px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  h3{
    position: relative;
    margin-right: 20px;
    flex-shrink: 0;
    height: 18px;
    line-height: 18px;
    font-weight: 400;
    font-size: 18px;
    color: #242D33;
    &:before{
      content: '';
      width: 4px;
      height: 20px;
      background: #3984F5;
      position: absolute;
      top: 0;
      left: -30px;
    }
  }
  .diagnostic_header{
    h5{
      font-weight: 400;
      font-size: 24px;
      color: #242D33;
      width: 100%;
      text-align: center;
      margin-bottom: 65px;
    }
    .look_out{
      color: #909DA5;
    }
    .header_mian{
      display: flex;
      .h_left{
        flex-grow: 1;
        .hl_header{
          display: flex;
          align-items: center;
          margin-top: 64px;
          .hl_score{
            width: 136px;
            height: 136px;
            margin-right: 51px;
            flex-shrink: 0;
          }
          .hl_txt{
            h4{
              color: #242D33;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              margin-bottom: 20px;
            }
            p{
              color: #242D33;
              font-size: 14px;
              max-width: 564px;
              .corporation{
                font-weight: bold;
              }
              .red{
                color: #FD5451;
              }
            }
          }
        }
        .hl_performance{
          h4{
            color: #242D33;
            font-size: 16px;
            margin-bottom: 15px;
            margin-top: 90px;
          }
          p{
            font-size: 14px;
            max-width: 828px;
            color: #242D33;
            .red{
              color: #FD5451;
            }
            .blue{
              color: #3984F5;
            }
          }
        }
      }
      .h_right{
        width: 720px;
        height: 400px;
        background: #FAFCFE;
      }
    }
  }
  .analysis_box{
    margin-top: 40px;
    .analysis_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .item_box{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 14px;
    }
    .ab_item{
      width: 49.5%;
      height: 400px;
      background: #FAFCFE;
      margin-top: 16px;
      padding: 24px;
      box-sizing: border-box;
      h4{
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        color: #242D33;
      }
      .revolution_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .chart_box{
          flex-grow: 1;
          height: 329px;
        }
        .revolution_table{
          width: 380px;
          ul{
            border: 1px solid #E1E8EC;
          }
          li{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #E1E8EC;
            padding: 20px 0 19px 0;
            box-sizing: border-box;
            &:last-child{
              border-bottom: none;
            }
            .r_name{
              text-align: center;
              width: 20%;
              font-weight: bold;
              font-size: 12px;
              color: #242D33;
              .red{
                color: #FD5451;
                font-weight: 400;
                font-size: 14px;
              }
            }
            .r_txt{
              width: 340px;
              box-sizing: border-box;
              min-height: 39px;
              p{
                color: #242D33;
                line-height: 19px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .solution_box{
    margin-top: 40px;
    .solution_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .solution_main{
      margin-top: 22px;
      p{
        font-weight: 400;
        font-size: 14px;
        color: #242D33;
        line-height: 30px;
      }
    }
  }
  .recommended_box{
    margin-top: 40px;
    .recommended_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line{
        background: #EDF1F4;
        flex-grow: 1;
        height: 1px;
      }
    }
    .recommended_main{
      ul{
        margin-top: 12px;
        overflow: hidden;
        li{
          width: 32.6%;
          height: 164px;
          background: #FAFCFE;
          float: left;
          margin-right: 1.1%;
          margin-top: 18px;
          display: flex;
          padding: 0 10px 0 20px;
          box-sizing: border-box;
          &:nth-child(3n){
            margin-right: 0;
          }
          .r_logo{
            margin-top: 20px;
            width: 96px;
            height: 96px;
            margin-right: 20px;
            flex: 0 0 auto;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .r_content{
            margin-top: 10px;
            flex-grow: 1;
            h4{
              font-weight: 400;
              font-size: 16px;
              color: #242D33;
              line-height: 36px;
            }
            .tag_box{
              overflow: hidden;
              div{
                float: left;
                padding: 7px 11px 8px 10px;
                color: #3984F5;
                background: #EFF5FF;
                margin-right: 8px;
              }
            }
            p{
              margin-top: 7px;
              font-weight: 400;
              font-size: 12px;
              color: #6C7880;
              line-height: 18px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .r_star{
              display: flex;
              margin-top: 9px;
              .star_name{
                color: #6C7880;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}
.btn_box{
  margin-top: 18px;
  a{
    display: inline-block;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #EEF1F3;
    border-radius: 4px;
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #6C7880;
  }
  .blue{
    background: #3984F5;
    color: #fff;
  }
}
::v-deep .quest-scroll {
  height: 320px;
  .el-scrollbar__wrap {
    overflow-x: hidden;
    //display: flex;
    //align-items: center;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
  }
  .el-scrollbar__thumb {
    background: #A7B2B8;
  }
}
</style>